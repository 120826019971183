import React from 'react'
import styles from './Typos.module.scss'
import { Typography, Divider } from 'antd'

const { Title, Paragraph, Text } = Typography

// TITLE
const h1 = ({children, id}) => <Title id={id}>{children}</Title>
const h2 = ({children, id}) => <Title id={id} level={2}>{children}</Title>
const h3 = ({children, id}) => <Title id={id} level={3}>{children}</Title>
const h4 = ({children, id}) => <Title id={id} level={4}>{children}</Title>

// TEXT
const p = ({children}) => <Paragraph>{children}</Paragraph>
const strong = ({children}) => <Text strong>{children}</Text>
const u = ({children}) => <Text underline>{children}</Text>
const del = ({children}) => <Text delete>{children}</Text>
const code = ({children}) => <Text code>{children}</Text>
const a = ({href, children}) => <a href={href} rel="noopener noreferrer"><Text type="secondary">{children}</Text></a>
const blockquote = ({children}) => <blockquote className={styles.typosBlockquote}>{children}</blockquote>
const hr = () => <Divider/>

export default {
  h1,
  h2,
  h3,
  h4,
  p,
  strong,
  u,
  del,
  code,
  a,
  hr,
  blockquote
}