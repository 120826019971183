import React, { Fragment } from 'react'
import { Avatar, Typography, Col } from 'antd'

const { Title, Text } = Typography

const Bio = ({ config }) => (
  <Fragment>
    <Col xs={6} md={{ span: 4, offset: 1 }} lg={{ span: 3, offset: 3 }} xl={{ span: 2, offset: 4 }}>
      <a href={config.link} target="_blank" title={config.name}>
        <Avatar size={64} src={config.avatar} />
      </a>
    </Col>
    <Col xs={18} md={18} lg={15} xl={12}>
      <Title level={3}>{config.name}</Title>
      <Text type="secondary">{config.description}</Text>
    </Col>
  </Fragment>
)

export default Bio
