import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const table = ({children}) => (
  <TableContainer component={Paper}>
    <Table>
      {children}
    </Table>
  </TableContainer>
)
const thead = ({children}) => <TableHead>{children}</TableHead>
const tbody = ({children}) => <TableBody>{children}</TableBody>
const tr = ({children}) => <TableRow>{children}</TableRow>
const td = ({children, style}) => <TableCell align={style == undefined ? 'left' : style.textAlign}>{children[0]}</TableCell>

export default {
  table,
  thead,
  tbody,
  tr,
  td,
  th: td
}