import React from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import config from '../../data/SiteConfig'
import styles from './template.module.scss'
import _ from 'lodash'

import Layout from '../layout'
import Title from '../elements/Title'

import { PageHeader, Tag, Row, Divider, Typography } from 'antd'
const { Text } = Typography

import Markdown from '../components/Markdown'
import SEO from '../components/SEO'
import Bio from '../components/Bio'
import SocialLinks from '../components/SocialLinks'
import PostNav from '../components/PostNav'

export default ({ data, pageContext }) => {
  const { slug, ...postNav } = pageContext
  const postNode = data.markdownRemark
  const post = postNode.frontmatter
  const date = postNode.fields.date
  if (!post.id) {
    post.id = slug
  }
  return (
    <Layout>
      <main>
        <Helmet>
          <title>{`${post.title} | ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postPath={slug} postNode={postNode} postSEO />
        <div>

          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title={<Title>{post.title}</Title>}
            tags={[
              <Tag color="gold" key="time">{`${postNode.timeToRead} Min`}</Tag>,
              <Tag color="volcano" key="date">{date}</Tag>,
            ]}
            className={styles.pageHeader}
          >
           {post.tags &&
            <Row>
              {post.tags.map(tag => <Link to={`/tag/${_.kebabCase(tag)}`} key={tag}><Tag>{`#${tag}`}</Tag></Link>)}
            </Row>
            }
          </PageHeader>
          
          <Row gutter={config.gutter} className={styles.postBody}>
            <Markdown data={postNode.htmlAst} />
            <Divider><Text type="secondary">Partager l'article</Text></Divider>
            <SocialLinks postPath={slug} postNode={postNode} />
            <Divider><Text type="secondary">Écrit par</Text></Divider>
            <Bio config={post.author} />
            <PostNav context={postNav} />
          </Row>

        </div>
      </main>
    </Layout>
  )
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      timeToRead
      excerpt
      frontmatter {
        title
        date
        tags
        author {
          name
          avatar
          description
          link
        }
      }
      fields {
        slug
        date(formatString: "DD MMM YYYY", locale: "fr")
      }
    }
  }
`
