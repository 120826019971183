import React from 'react'
import rehypeReact from 'rehype-react'
import '../styles/prism-light.css'

import { Col } from 'antd'

import Typos from '../elements/Typos'
import Tables from '../elements/Tables'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { 
    ...Typos,
    ...Tables,
  }
}).Compiler

const Markdown = ({ data }) => (
  <Col sm={24} md={{ span: 22, offset: 1 }} lg={{ span: 18, offset: 3 }} xl={{ span: 16, offset: 4 }}>
    {renderAst(data)}
  </Col>
)

export default Markdown