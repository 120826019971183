import React from 'react'

import { Button, Col, Row } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const PostNav = ({ context: {nexttitle, nextslug, prevtitle, prevslug} }) => (
  <Col span={24}>
    <Row justify="space-between">
      <Button href={prevslug} rel="prev"><LeftOutlined /> {prevtitle}</Button>
      <Button href={nextslug} rel="next">{nexttitle} <RightOutlined /></Button>
    </Row>
  </Col>
)

export default PostNav